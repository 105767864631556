import { courseCatalog } from '@/api/research/curriculum'

export default {
  data () {
    return {
      tableList: [],
      tableArr: [], // 表格重组数据
      cascaderList: [] // 选择器重组数据
    }
  },
  mounted () {
    this.courseCatalog()
  },
  methods: {
    // 获取课程类别
    async courseCatalog () {
      this.tableArr = []
      this.cascaderList = []
      const res = await courseCatalog({
        pid: '',
        pageNum: 1,
        pageSize: 1000
      })
      this.tableList = res.body
      this.buildTree(res.body)
      this.cascaderList = [...this.tableArr]
    },

    // 构建 树形数据
    buildTree (list) {
      let dataList = []
      for (let index in list) {
        dataList[list[index].id] = list[index]
      }
      for (let i in dataList) {
        if (dataList[i].pid) {
          if (!dataList[dataList[i].pid].children) {
            dataList[dataList[i].pid].children = []
          }
          dataList[dataList[i].pid].children.push(dataList[i])
        } else {
          this.tableArr.push(dataList[i])
        }
      }
    }
  }
}
